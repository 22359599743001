@charset "utf-8";

// Animations

$intro-transition  : none;
$global-transition : none;

// Breakpoints

$x-large: 1920px !default;  // Originally 1280px

// Typography

$caption-font-family: -apple-system, BlinkMacSystemFont, "Roboto", "Segoe UI", "Helvetica Neue", "Lucida Grande", Arial, sans-serif !default;

// Colors

$twitter-color: rgb(29, 155, 240) !default;

// Theme imports

@import "minimal-mistakes/skins/onloft"; // skin
@import "minimal-mistakes"; // main partials

// Page sizing

.onloft-full-width-page {
    .page {
      @include breakpoint($large) {
        width: 100%;
        padding-right: 0;
      }
  
      @include breakpoint($x-large) {
        width: 100%;
        padding-right: 0;
      }
    }
  }

// Headings

.onloft-large-headings h2 {
    margin-top: 0;
    font-size: $h-size-1;
    border-bottom-style: none;
}

.onloft-large-headings h3 {
    font-size: $h-size-2;
}

.onloft-large-headings h4 {
    font-size: $h-size-3;
}

.onloft-large-headings h5 {
    font-size: $h-size-4;
}

.onloft-large-headings h6 {
    font-size: $h-size-5;
}

.onloft-large-headings h7 {
    font-size: $h-size-6;
}

// Masthead

.masthead {
    background-color: $onloft-header-background-color;
    border-bottom: none;  // Remove masthead separator line
    animation: none;  // Disable masthead fade animation
}

.greedy-nav {
    background-color: $onloft-header-background-color;
}

.greedy-nav .visible-links a:before {  // Disable masthead active link animation
    background: none;
    transition: none;
    transform: none;
    -webkit-transform: none;
}

// Masthead menu button

.navicon {
    background-color: $masthead-link-color;  // Menu button color (center bar)

    &:before,
    &:after {
      background: $masthead-link-color; // Menu button color (top/bottom bars)
    }
}

.greedy-nav__toggle:hover {
    .navicon,
    .navicon:before,
    .navicon:after {
      background: $masthead-link-color-hover;  // Menu button hover color
    }
}

// Masthead overlay menu

.greedy-nav__toggle {
    &.close {
      &:before {
        opacity: 0.6;  // Page overlay opacity
      }
    }
  }

.greedy-nav .hidden-links .masthead__menu-item a {
    color: rgb(83, 89, 94);  // Overlay menu text color
}

// Link decoration

h1 a {
    text-decoration: none !important;
}

a {
    text-decoration: none;
}

// Notice box

.notice {
    font-size: 1em !important;
}

.notice a {
    color: $link-color;
}

// Onloft header

.onloft-header {
    background-color: $onloft-header-background-color;
    color: white;
}

.onloft-header-content {
    margin-left: auto;
    margin-right: auto;
    padding: 2em;
}

@media (min-width: 80em) {
    .onloft-header-content {
        max-width: 1280px;
    }
}

.onloft-header-content h1 {
    margin: 0 !important;
    font-size: 3em;
}

.onloft-header-content p {
    margin: 1em 0 0 0;
}

.onloft-header-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(275px, 1fr));
    gap: 2em;
}

.onloft-header-flex-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 1.5em 2em; // vertical horizontal
}

.onloft-header-image-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2em;
}

// App header

.onloft-app-header {
    display: grid;
    gap: 2em;
    grid-template-columns: repeat(auto-fit, minmax(275px, 1fr));
}

.onloft-app-header h1 {
    margin: 0 !important;
    font-size: 3em;
}

.onloft-app-header h2 {
    margin: 0 0 0.5em 0 !important;
    padding-top: 0.2em;
    font-size: 1em;
}

.onloft-app-header p {
    margin: 0 !important;
    padding-top: 1em;
}

.onloft-app-header-icon {
    width: 9em;
    height: 9em;
    margin-bottom: 0.5em;
}

.onloft-app-header-badge {
    width: 10em;
    margin-top: 1em;
}

// Feature grid

.onloft-feature-grid {
    background-color: rgb(247, 248, 250);
    padding: 2em;
    border-radius: 1em;
    margin-bottom: 1em;

    display: grid;
    gap: 2em;
    grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));

    figure {
        margin: 0;
    }
}

.onloft-faq {
    background-color: rgb(247, 248, 250);
    padding: 2em;
    border-radius: 1em;
    margin-bottom: 1em;
}

.onloft-faq p:last-of-type {
    margin-bottom: 0;
}

// Multi columns

.onloft-double-column {
    columns: 350px 2;
    column-gap: 3em;
}

.onloft-triple-column {
    columns: 350px 3;
    column-gap: 3em;
}

.onloft-column-box {
    break-inside: avoid-column;
    padding-bottom: 1em;
}

.onloft-column-box h3 {
    margin-top: 0;
}

// App grid

.onloft-app-grid {
    display: grid;
    gap: 1em;
    grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
}

.onloft-app-box {
    border-radius: 1em;
    color: white;

    a {
        text-decoration: none !important;
    }
}

.onloft-app-box-content {
    padding: 2em 2em 0 2em;
    color: white;

    h2 {
        margin: 0.3em 0 0 0;
        padding: 0;
        border-bottom-style: none;
        font-size: 2em;
    }

    h3 {
        margin: 0 0 1em 0;
        font-size: 1em;
    }
}

.onloft-app-box-actions {
    padding: 0em 2em 2em 2em;
}

.onloft-app-box-icon {
    width: 5em;
    height: 5em;
    box-shadow: none !important;
}

// Posts

.onloft-post-grid {
    display: grid;
    column-gap: 2em;
    grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));

    img {
        display: block;  // Center image to parent figure
        margin-left: auto;
        margin-right: auto;

        max-height: 600px;
        width: auto;  // Maintain aspect ratio
    }
}

.onloft-post-section {
    margin-bottom: 2em;
}

.onloft-post-meta-header {
    margin-bottom: 1em;
    color: rgb(150, 150, 150);
    font-size: 0.9em;
    font-weight: 400;
}

// Figures

figure {
    display: inline-block;
}

figure img {
    vertical-align: top;
    margin-bottom: 0.7em;
}

figure figcaption {
    margin: 0;
    text-align: center;
    font-size: 0.7em;
}

// Buttons

.onloft-button {
    margin-bottom: 0.9em;
    margin-right: 0.5em;
}

.onloft-button i {
    margin-right: 0.2em;
}

.onloft-button img {
    height: 1em;
    margin-right: 0.1em;
}

// Footers

.page__footer-follow li {
    text-transform: none;
}

.page__footer {
    margin-top: 0;
}

// Custom button styles

.btn--tweetlogix {
    background-color: rgb(26, 121, 210);
    color: white;
}

.btn--tweetlogix:hover {
    background-color: mix(#000, rgb(26, 121, 210), 20%);
    color: white;
}